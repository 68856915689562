import { Grid, Box, Typography } from '@mui/material';
import image from '../../../../../../../assets/image/atab-logo.png';
import { imgUrl } from 'src/Helpers/utils/Constant';
import moment from 'moment';
import { IFromData } from 'src/Types/module/member/AdmissionFromData';

const FirstPageFrom = ({ fromData }: { fromData: IFromData }) => {
  return (
    <Box mt={2}>
      <Box>
        {/* <Box sx={{ textAlign: "start" }}> */}
        <span>Print : {new Date().toISOString().split('T')[0]}</span>
        {/* </Box> */}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box mt={1.5}>
              <img src={image} alt='Atab Logo' loading='lazy' height='50px' />
            </Box>
            <Box>
              <Typography fontSize={15}>
                To <br />
                <span style={{ fontWeight: 'bold' }}>
                  The secretary general
                </span>
                <br />
                Association of Travel Agents of Bangladesh <br />
                (ATAB) Sattara center (15th Floor) <br />
                30/A,Nayapaltan,VIP Road,Dhaka 1000,Bangladesh
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <img
                src={`${imgUrl}/${fromData.company_owner_photo}`}
                loading='lazy'
                alt=''
                width='150px'
                height='150px'
                style={{ marginLeft: '10px', border: '1px solid gray' }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box mt={2}>
        <Typography fontSize={15}>
          Application date:{' '}
          {fromData.application_date &&
            moment(fromData.application_date).format('DD/MM/YYYY')}
        </Typography>
        <Typography fontSize={14}>
          <span style={{ fontWeight: 'bold' }}>Through:</span> Chairman of
          Secretary, Zonal Committee,{' '}
          <span style={{ fontWeight: 'bold' }}>
            Dhaka / Chittagong / sylhet
          </span>
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            textDecoration: 'underline',
            textUnderlineOffset: '5px',
            textDecorationThickness: '3px',
            fontSize: 17,
          }}
          pt={1}
          pb={1}
        >
          Subject: Application of Membership
        </Typography>
        <Typography textAlign={'justify'} fontSize={15}>
          Dear Sir, <br />I / We here by apply to become a member of Association
          of Travel Agents of Bangladesh (ATAB) and Undertake to abide by the
          memorandum, Articles of Association and conduct rules of ATAB along
          with the laws there under as are presently and may come in force from
          time to time.
        </Typography>
        <Typography my={1} fontSize={15}>
          Particulars of the Organization required of Membership has been given
          below :-
        </Typography>
      </Box>
      <Box mt={1}>
        <table className='member-info-print'>
          <tbody>
            <tr>
              <th style={{ borderRight: '1px solid gray' }}>1</th>
              <th className='table-head'>Name of the Organization</th>
              <td colSpan={6}>
                {fromData.user_member_company_name?.charAt(0).toUpperCase() +
                  fromData.user_member_company_name?.slice(1)}
              </td>
            </tr>

            <tr>
              <th style={{ borderRight: '1px solid gray' }}>2</th>
              <th style={{ textAlign: 'start', borderRight: '1px solid gray' }}>
                Full Address
              </th>
              <td colSpan={6}>{fromData.addressline1}</td>
            </tr>

            <tr>
              <th style={{ borderRight: '1px solid gray' }}></th>
              <th style={{ textAlign: 'start', borderRight: '1px solid gray' }}>
                Police station
              </th>
              <td>{fromData.police_station}</td>
              <th className='table-middle-head'>Zone</th>
              <td>{fromData.zone_name}</td>
            </tr>
            <tr>
              <th style={{ borderRight: '1px solid gray' }}></th>
              <th style={{ textAlign: 'start', borderRight: '1px solid gray' }}>
                Mobile Number
              </th>
              <td>{fromData.phone}</td>
              <th className='table-middle-head'>Fax</th>
              <td>None</td>
            </tr>

            <tr
              style={{
                border: '1px solid gray',
                borderCollapse: 'collapse',
                width: '100%',
              }}
            >
              <th style={{ borderRight: '1px solid gray' }}></th>
              <th style={{ textAlign: 'start', borderRight: '1px solid gray' }}>
                Telephone Number
              </th>
              <td>{fromData.cellphone}</td>
            </tr>
            <tr>
              <th style={{ borderRight: '1px solid gray' }}></th>
              <th style={{ textAlign: 'start', borderRight: '1px solid gray' }}>
                Email
              </th>
              <td>{fromData.email}</td>
            </tr>
            <tr>
              <th style={{ borderRight: '1px solid gray' }}></th>
              <th style={{ textAlign: 'start', borderRight: '1px solid gray' }}>
                Website
              </th>
              <td>{fromData.member_company_website}</td>
            </tr>
            <tr>
              <th style={{ borderRight: '1px solid gray' }}>3</th>
              <th style={{ textAlign: 'start' }} colSpan={3}>
                Particulars of the Owner/Owners
              </th>
            </tr>

            {fromData?.multipleOwnerAndDesignation?.map((sOwner, index) => {
              return (
                <tr key={index}>
                  <th style={{ borderRight: '1px solid gray' }}>
                    {index === 0 && 'i'}
                    {index === 1 && 'ii'}
                    {index === 2 && 'iii'}
                  </th>
                  <th
                    style={{
                      textAlign: 'start',
                      borderRight: '1px solid gray',
                    }}
                  >
                    Name
                  </th>
                  <td>{sOwner.company_owner_name}</td>
                  <th className='table-middle-head'>Designation</th>
                  <td colSpan={5}>{sOwner.company_owner_designation}</td>
                </tr>
              );
            })}

            <tr>
              <th style={{ borderRight: '1px solid gray' }}>4</th>
              <th style={{ textAlign: 'start', borderRight: '1px solid gray' }}>
                Permanent Address
              </th>
              <td>{fromData.company_owner_address}</td>
              <th className='table-middle-head'>Mob No</th>
              <td colSpan={5}>{fromData.company_owner_phone}</td>
            </tr>

            <tr>
              <th style={{ borderRight: '1px solid gray', width: '7%' }}>5</th>
              <th colSpan={6} style={{ textAlign: 'start' }}>
                Particulars of person ,Who will Represent his Organization in
                ATAB (if Other than Owner)
              </th>
            </tr>

            <tr>
              <th style={{ borderRight: '1px solid gray' }}></th>
              <th style={{ textAlign: 'start', borderRight: '1px solid gray' }}>
                Name
              </th>
              <td>{fromData.user_member_representative_name}</td>
              <th className='table-middle-head'>Designation</th>
              <td colSpan={6}>
                {fromData.user_member_representative_designation}
              </td>
            </tr>

            <tr style={{ border: '1px solid gray' }}>
              <th style={{ borderRight: '1px solid gray' }}></th>
              <th className='table-head'>Full Address</th>
              <td colSpan={6}>
                {fromData.user_member_representative_full_address}
              </td>
            </tr>

            <tr>
              <th style={{ borderRight: '1px solid gray' }}></th>
              <th style={{ textAlign: 'start', borderRight: '1px solid gray' }}>
                Phone (Office)
              </th>
              <td>{fromData.user_member_representative_phone}</td>
              <th className='table-middle-head'>Resident</th>
              <td>None</td>
            </tr>

            <tr style={{ border: '1px solid gray' }}>
              <th style={{ borderRight: '1px solid gray' }}>6</th>
              <th style={{ textAlign: 'start' }}>Status of Ownership</th>
            </tr>

            <tr>
              <th style={{ borderRight: '1px solid gray' }}></th>
              <th style={{ textAlign: 'start', borderRight: '1px solid gray' }}>
                Company/Incorporated
              </th>
              <td>
                {fromData.company_ownership_status === 'Incorporator'
                  ? 'YES'
                  : 'N/A'}
              </td>
              <th className='table-middle-head'>Partnership</th>
              <td>
                {' '}
                {fromData.company_ownership_status === 'Partnership'
                  ? 'YES'
                  : 'N/A'}
              </td>
              <th className='table-middle-head'>Proprietorship</th>
              <td>
                {fromData.company_ownership_status === 'Proprietorship'
                  ? 'YES'
                  : 'N/A'}
              </td>
            </tr>
          </tbody>
        </table>

        <Box mt={2} mb={3}>
          <table className='member-info-print'>
            <tbody>
              <tr>
                <th style={{ borderRight: '1px solid gray' }}>7</th>
                <th className='table-head'>Date of Establishment</th>
                <td>
                  {fromData.member_company_stablished_date &&
                    moment(fromData.member_company_stablished_date).format(
                      'DD/MM/YYYY'
                    )}
                </td>
              </tr>
              <tr>
                <th style={{ borderRight: '1px solid gray', width: '3.4%' }}>
                  8
                </th>
                <th style={{ textAlign: 'start', width: '30%' }}>
                  NID Information
                </th>
              </tr>
              <tr>
                <th style={{ borderRight: '1px solid gray' }}></th>
                <th
                  style={{ textAlign: 'start', borderRight: '1px solid gray' }}
                >
                  Owner
                </th>
                <td>{fromData.company_owner_nid}</td>
                <th className='table-middle-head'>Representative</th>
                <td>{fromData.user_member_representative_nid_no}</td>
              </tr>
            </tbody>
          </table>
        </Box>
      </Box>
    </Box>
  );
};

export default FirstPageFrom;
