import { useState, useEffect, useRef } from 'react';
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  CardHeader,
  TextField,
  Container,
  Grid,
  Typography,
  Button,
} from '@mui/material';
import { Imembers } from 'src/Types/module/member/memberTypes';
import { useDebounce } from 'src/hooks/useDebounce';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import { useLocation, useSearchParams } from 'react-router-dom';
import CommonMemberTableRow from './CommonMemberTableRow';
import ExcelGenerator from 'src/components/ExcelGenerator/ExcelGenerator';
import PdfGenerator from 'src/components/PdfGenerator/PdfGenerator';
import { HttpClient } from 'src/Helpers/http/http';
import { DatePicker } from '@mui/x-date-pickers';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import CreateMember from '../CreateMember/CreateMember';
import CommonDateRangePicker from 'src/common/CommonDateRangePicker';
import dayjs from 'dayjs';

type TDate = {
  startDate: Date | null;
  endDate: Date | null;
};
const CommonMemberTable = ({ status, title }) => {
  const location = useLocation();
  const { user } = useAuthContext();
  const [allMembers, setAllMembers] = useState<Imembers[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(25);
  const [searchCompanyVal, setSearchCompanyVal] = useState<string>('');

  // Modal
  const [open, setOpen] = useState(false);
  // Pagination
  const [searchParams, setSearchParams] = useSearchParams({
    page: '0',
  });
  const page = parseInt(searchParams.get('page'));
  const [total, setTotal] = useState<number>(0);
  // Search by Time and Zone
  const searchQuery = useDebounce(searchCompanyVal, 1000);
  const [dateRange, setDateRange] = useState<{
    start_date: string;
    end_date: string;
  }>({
    start_date: '',
    end_date: dayjs().format('YYYY-MM-DD'),
  });
  const [zone, setZone] = useState<number>(0);

  // /member-list/cceehkr - list;
  useEffect(() => {
    setIsLoading(true);
    (async function () {
      try {
        if (!searchQuery) {
          let uri = `${url}/api/atab/user-member/get/all/by/date/zone/status/for-admin?status=${status}&`;
          if (dateRange.start_date && dateRange.end_date) {
            uri += `from_date=${dateRange.start_date}&to_date=${dateRange.end_date}&`;
          }
          if (zone > 0) {
            uri += `zone=${zone}&`;
          }
          if (location.pathname === '/member-list/checker-list') {
            uri += `admin_id=${user?.user_admin_id}&`;
          }
          if (page >= 0) {
            uri += `skip=${limit * page}&limit=${limit}`;
          }
          const data: any = await HttpClient.get(uri);

          if (data.success) {
            setAllMembers(data.data);
            setTotal(data.total);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        } else {
          const data: any = await HttpClient.get(
            `${url}/api/atab/user-member/get/all/by/date/zone/status/for-admin?status=${status}&company_name=${searchQuery}&skip=0`
            //`${url}/api/atab/user-member/search-member/by-company/${searchQuery}?status=${status}&zone=${zone}`
          );
          if (data.success) {
            setAllMembers(data.data);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, [searchQuery, page, limit, dateRange, zone]);

  const getDownloadData = async () => {
    try {
      const data: any = await HttpClient.get(
        `${url}/api/atab/user-member/download/member/data/for-admin?type=excel&zone=${zone}${
          status !== 'all' ? `&status=${status}` : ''
        }`
      );

      if (data.success) {
        return data;
      } else {
        return [];
      }
    } catch (err) {
      console.error('Error fetching download data:', err);
      return [];
    }
  };

  return (
    <>
      <Container maxWidth='xl'>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='stretch'
          spacing={3}
        >
          <Grid item xs={12}>
            <Card sx={{ p: 2 }}>
              <Grid
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                  gap: 1,
                }}
                container
              >
                <Grid item>
                  <CommonDateRangePicker
                    setDateRange={setDateRange}
                    dateRange={dateRange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id='demo-simple-select-label'>
                      Select zone
                    </InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={zone}
                      defaultValue={0}
                      label='Select zone'
                      onChange={(e: any) => setZone(e.target.value)}
                      size='small'
                    >
                      <MenuItem value={0}>All</MenuItem>
                      <MenuItem value={1}>Dhaka</MenuItem>
                      <MenuItem value={2}>Chittagong</MenuItem>
                      <MenuItem value={3}>Sylhet</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12} pb={2}>
            <Card>
              <CardHeader
                action={
                  <Box display='flex' justifyContent='space-between' gap={1}>
                    <TextField
                      id='outlined-basic'
                      label='Search Company'
                      variant='outlined'
                      size='small'
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setSearchCompanyVal(e.target.value)
                      }
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      <ExcelGenerator getDownloadData={getDownloadData} />
                      <PdfGenerator getDownloadData={getDownloadData} />
                      {/* <Button
                        onClick={() => {
                          generatePDF(componentRef, {
                            filename: `member_list.pdf`,
                            // page: {
                            //   margin: Margin.SMALL,
                            //   format: "letter",
                            //   orientation: "landscape",
                            // },
                          });

                          getDownloadData2();
                        }}
                        variant="outlined"
                        size="small"
                      >
                        Download pdf
                      </Button> */}
                    </Box>

                    {user?.user_admin_role === 'Super Admin' ||
                    user?.user_admin_role === 'Admin' ? (
                      <Button onClick={() => setOpen(true)} variant='contained'>
                        Create Member
                      </Button>
                    ) : (
                      ''
                    )}
                  </Box>
                }
                title={`${title} (${total})`}
              />
              <Divider />

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Company Name</TableCell>
                      <TableCell>Owner name</TableCell>
                      <TableCell>Phone</TableCell>
                      <TableCell>Zone</TableCell>
                      {user.user_admin_role !== 'Application Inspection' && (
                        <TableCell>Inspect by</TableCell>
                      )}

                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading ? (
                      <TableLoader />
                    ) : allMembers.length ? (
                      <>
                        {allMembers.map((allMembers: Imembers) => {
                          return (
                            <CommonMemberTableRow
                              key={allMembers.user_member_id}
                              allMembers={allMembers}
                            />
                          );
                        })}
                      </>
                    ) : (
                      <TableNotFound />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box p={2}>
                <TablePagination
                  component='div'
                  count={total}
                  onPageChange={(_event: any, newPage: number) =>
                    setSearchParams({ page: newPage.toString() })
                  }
                  onRowsPerPageChange={(event) =>
                    setLimit(parseInt(event.target.value))
                  }
                  page={page && page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[25, 50, 75, 100]}
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
      {/* create member */}
      <ModalsWrapper
        modalData={{ title: 'Create Member' }}
        setShowModal={setOpen}
        showModal={open}
      >
        <CreateMember setAllMembers={setAllMembers} handleClose={setOpen} />
      </ModalsWrapper>
    </>
  );
};

export default CommonMemberTable;
